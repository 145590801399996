import { Goods } from "@/api/interface";
import { uploadFile } from "@/api/modules/common";
import { isArray } from "@/utils/is";

/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export function localGet(key: string) {
	const value = window.localStorage.getItem(key);
	try {
		return JSON.parse(window.localStorage.getItem(key) as string);
	} catch (error) {
		return value;
	}
}

/**
 * @description 存储localStorage
 * @param {String} key Storage名称
 * @param {Any} value Storage值
 * @return void
 */
export function localSet(key: string, value: any) {
	window.localStorage.setItem(key, JSON.stringify(value));
}

/**
 * @description 清除localStorage
 * @param {String} key Storage名称
 * @return void
 */
export function localRemove(key: string) {
	window.localStorage.removeItem(key);
}

/**
 * @description 清除所有localStorage
 * @return void
 */
export function localClear() {
	window.localStorage.clear();
}

/**
 * @description 判断数据类型
 * @param {Any} val 需要判断类型的数据
 * @return string
 */
export function isType(val: any) {
	if (val === null) return "null";
	if (typeof val !== "object") return typeof val;
	else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
}

/**
 * 判断两个对象是否相同
 * @param a 要比较的对象一
 * @param b 要比较的对象二
 * @returns 相同返回 true，反之则反
 */
export function isObjectValueEqual(a: { [key: string]: any }, b: { [key: string]: any }) {
	if (!a || !b) return false;
	let aProps = Object.getOwnPropertyNames(a);
	let bProps = Object.getOwnPropertyNames(b);
	if (aProps.length != bProps.length) return false;
	for (let i = 0; i < aProps.length; i++) {
		let propName = aProps[i];
		let propA = a[propName];
		let propB = b[propName];
		if (!b.hasOwnProperty(propName)) return false;
		if (propA instanceof Object) {
			if (!isObjectValueEqual(propA, propB)) return false;
		} else if (propA !== propB) {
			return false;
		}
	}
	return true;
}

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return number
 */
export function randomNum(min: number, max: number): number {
	let num = Math.floor(Math.random() * (min - max) + max);
	return num;
}

/**
 * @description 获取当前时间
 * @return string
 */
export function getTimeState() {
	// 获取当前时间
	let timeNow = new Date();
	// 获取当前小时
	let hours = timeNow.getHours();
	// 判断当前时间段
	if (hours >= 0 && hours <= 10) return `Good morning ⛅`;
	if (hours >= 10 && hours <= 18) return `Good afternoon 🌞`;
	if (hours >= 18 && hours <= 24) return `Good evening 🌛`;
}

/**
 * @description 获取浏览器默认语言
 * @return string
 */
export function getBrowserLang() {
	let browserLang = navigator.language ? navigator.language : navigator.browserLanguage;
	let defaultBrowserLang = "";
	if (browserLang.toLowerCase() === "cn" || browserLang.toLowerCase() === "zh" || browserLang.toLowerCase() === "zh-cn") {
		defaultBrowserLang = "zh";
	} else {
		defaultBrowserLang = "en";
	}
	return defaultBrowserLang;
}

/**
 * @description 递归查询当前路由所对应的路由
 * @param {Array} menuList 所有菜单列表
 * @param {String} path 当前访问地址
 * @return array
 */
export function filterCurrentRoute(menuList: Menu.MenuOptions[], path: string) {
	let result = {};
	for (let item of menuList) {
		if (item.path === path) return item;
		if (item.children) {
			const res = filterCurrentRoute(item.children, path);
			if (Object.keys(res).length) result = res;
		}
	}
	return result;
}

/**
 * @description 扁平化数组对象(主要用来处理路由菜单)
 * @param {Array} menuList 所有菜单列表
 * @return array
 */
export function getFlatArr(menulist: Menu.MenuOptions[]) {
	return menulist.reduce((pre: Menu.MenuOptions[], current: Menu.MenuOptions) => {
		let flatArr = [...pre, current];
		if (current.children) flatArr = [...flatArr, ...getFlatArr(current.children)];
		return flatArr;
	}, []);
}

/**
 * @description 拍平后端接口返回的menu-list
 * @param {Array} menuList 后端接口返回的menulist
 * @return array
 */
export function flatMenuList(menulist: any, childrenKey: string = "child_list") {
	return menulist.reduce((pre: any, current: any) => {
		let flatArr = [...pre, current];
		if (current[childrenKey]) flatArr = [...flatArr, ...flatMenuList(current[childrenKey], childrenKey)];
		return flatArr;
	}, []);
}

/**
 * @description 处理后台接口返回的路由表
 * @param {Array} menuList 所有菜单列表
 * @return array
 */
export function assembleRouter(menulist: Record<string, any>, router: Menu.MenuOptions[]) {
	for (let i = 0; i < router.length; i++) {
		const item = router[i];
		const matchRoute = menulist.find((menu: any) => menu.url_str === item.path);
		if (matchRoute) {
			item.meta.title = matchRoute.title;
			item.meta.enTitle = matchRoute.en_title;
			if (item.children) {
				assembleRouter(menulist, item.children);
			}
		} else {
			router.splice(i, 1);
			i--;
		}
	}
	return router;
}

/**
 * @description 使用递归，过滤需要缓存的路由
 * @param {Array} menuList 所有菜单列表
 * @param {Array} cacheArr 缓存的路由菜单 name ['**','**']
 * @return array
 * */
export function getKeepAliveRouterName(menuList: Menu.MenuOptions[], keepAliveArr: string[] = []) {
	menuList.forEach(item => {
		item.meta.isKeepAlive && item.name && keepAliveArr.push(item.name);
		item.children?.length && getKeepAliveRouterName(item.children, keepAliveArr);
	});
	return keepAliveArr;
}

/**
 * @description 使用递归，过滤出需要渲染在左侧菜单的列表（剔除 isHide == true 的菜单）
 * @param {Array} menuList 所有菜单列表
 * @return array
 * */
export function getShowMenuList(menuList: Menu.MenuOptions[]) {
	let newMenuList: Menu.MenuOptions[] = JSON.parse(JSON.stringify(menuList));
	return newMenuList.filter(item => {
		item.children?.length && (item.children = getShowMenuList(item.children));
		return !item.meta?.isHide;
	});
}

/**
 * @description 使用递归处理路由菜单 path，生成一维数组(第一版本地路由鉴权会用到)
 * @param {Array} menuList 所有菜单列表
 * @param {Array} menuPathArr 菜单地址的一维数组 ['**','**']
 * @return array
 */
export function getMenuListPath(menuList: Menu.MenuOptions[], menuPathArr: string[] = []) {
	menuList.forEach((item: Menu.MenuOptions) => {
		typeof item === "object" && item.path && menuPathArr.push(item.path);
		item.children?.length && getMenuListPath(item.children, menuPathArr);
	});
	return menuPathArr;
}

/**
 * @description 使用递归，过滤出当前路径匹配的面包屑地址
 * @param {String} path 当前访问地址
 * @param {Array} menuList 所有菜单列表
 * @returns array
 */
export function getCurrentBreadcrumb(path: string, menuList: Menu.MenuOptions[]) {
	let tempPath: Menu.MenuOptions[] = [];
	try {
		const getNodePath = (node: Menu.MenuOptions) => {
			tempPath.push(node);
			if (node.path === path) throw new Error("Find IT!");
			if (node.children?.length) node.children.forEach(item => getNodePath(item));
			tempPath.pop();
		};
		menuList.forEach(item => getNodePath(item));
	} catch (e) {
		return tempPath;
	}
}

// /**
//  * @description 双重递归找出所有面包屑存储到 pinia/vuex 中
//  * @param {Array} menuList 所有菜单列表
//  * @returns array
//  */
// export function getAllBreadcrumbList(menuList: Menu.MenuOptions[]) {
// 	let handleBreadcrumbList: { [key: string]: any } = {};
// 	const loop = (menuItem: Menu.MenuOptions) => {
// 		if (menuItem?.children?.length) menuItem.children.forEach(item => loop(item));
// 		else handleBreadcrumbList[menuItem.path] = getCurrentBreadcrumb(menuItem.path, menuList);
// 	};
// 	menuList.forEach(item => loop(item));
// 	return handleBreadcrumbList;
// }

/**
 * @description 递归找出所有面包屑存储到 pinia/vuex 中
 * @param {Array} menuList 所有菜单列表
 * @param {Object} result 输出的结果
 * @param {Array} parent 父级菜单
 * @returns object
 */
export const getAllBreadcrumbList = (menuList: Menu.MenuOptions[], result: { [key: string]: any } = {}, parent = []) => {
	for (const item of menuList) {
		result[item.path] = [...parent, item];
		if (item.children) getAllBreadcrumbList(item.children, result, result[item.path]);
	}
	return result;
};

/**
 * @description 格式化表格单元格默认值(el-table-column)
 * @param {Number} row 行
 * @param {Number} col 列
 * @param {String} callValue 当前单元格值
 * @return string
 * */
export function defaultFormat(row: number, col: number, callValue: any) {
	// 如果当前值为数组,使用 / 拼接（根据需求自定义）
	if (isArray(callValue)) return callValue.length ? callValue.join(" / ") : "--";
	return callValue ?? "--";
}

/**
 * @description 处理无数据情况
 * @param {String} callValue 需要处理的值
 * @return string
 * */
export function formatValue(callValue: any) {
	// 如果当前值为数组,使用 / 拼接（根据需求自定义）
	if (isArray(callValue)) return callValue.length ? callValue.join(" / ") : "--";
	return callValue ?? "--";
}

/**
 * @description 根据枚举列表查询当需要的数据（如果指定了 label 和 value 的 key值，会自动识别格式化）
 * @param {String} callValue 当前单元格值
 * @param {Array} enumData 枚举列表
 * @param {String} type 过滤类型（目前只有 tag）
 * @return string
 * */
export function filterEnum(
	callValue: any,
	enumData: { [key: string]: any } | undefined,
	searchProps?: { [key: string]: any },
	type?: string
): string {
	const value = searchProps?.value ?? "value";
	const label = searchProps?.label ?? "label";
	let filterData: any = {};
	if (Array.isArray(enumData)) filterData = enumData.find((item: any) => item[value] === callValue);
	if (type == "tag") return filterData?.tagType ? filterData.tagType : "";

	if (!filterData) {
		return "--";
	}

	return typeof filterData[label] === "function" ? filterData[label]() : filterData[label];
}

//! 格式化商品分类为tree select格式
/**
 *
 * @param data 数据源
 * @param arr 转化之后的数据
 * @param needRoot 是否需要生成root分类
 * @param disabledOption  是否需要disable掉禁用状态的分类
 * @returns
 */
export const formatTreeSelect = (
	data: Goods.Category[] = [],
	arr: any = [],
	needRoot = true,
	disabledOption = false,
	labelName = "category_name",
	disabledStatus: number | undefined = undefined,
	enableFilterDisabled: boolean = false
) => {
	//顶级分类
	needRoot &&
		arr.push({
			label: "Root",
			value: 0,
			children: []
		});
	const dfs = (data: Goods.Category[] = [], depth = 1, arr: any = []) => {
		for (let i = 0; i < data.length; i++) {
			const item = data[i];

			if (enableFilterDisabled && item.status === disabledStatus) {
				continue;
			}

			const obj = {
				label: item[labelName],
				value: item.id,
				children: [],
				disabled: disabledOption && item.status === (disabledStatus !== undefined ? disabledStatus : 2)
			};

			arr.push(obj);

			if (item.children && depth <= 2) {
				obj.children = dfs(item.children, depth + 1);
			}
		}
		return arr;
	};

	return dfs(data, 1, arr);
};

// 生成 seo url
export const generateSeoUrl = (val: string) =>
	val
		.trim()
		.replace(/\s+/gi, "-")
		.replace(/[^a-zA-Z0-9-\.]+/g, "");

// url 正则校验
export const URLRegExp = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;

/**
 * 生成 A - Z 之间的字母
 */
export function generateLetterCode(): Array<string> {
	const result: any = [];

	for (let i = 65; i <= 90; i++) {
		result.push(String.fromCharCode(i));
	}

	return result;
}

export const getVideoCover = async (file: File, option: any = {}) => {
	return new Promise((resolve: Function) => {
		const { imgWidth = 400, imgHeight = 225 } = option; // 定义生成图片的宽高，其他宽高需求可以自己改
		let fileUrl = URL.createObjectURL(file);
		let videoElement: any = document.createElement("VIDEO");
		videoElement.src = fileUrl;
		videoElement.currentTime = 0.01;
		videoElement.oncanplay = async () => {
			let { videoWidth, videoHeight } = videoElement; // 获取video的宽高
			let x = 0,
				y = 0,
				width = 0,
				height = 0;
			// 计算缩小后图片的宽高以及canvas绘制的位置信息
			if (videoWidth / videoHeight >= 1.5) {
				width = imgWidth;
				height = videoHeight * (imgWidth / videoWidth);
				x = 0;
				y = (imgHeight - height) / 2;
			} else {
				height = imgHeight;
				width = videoWidth * (imgHeight / videoHeight);
				y = 0;
				x = (imgWidth - width) / 2;
			}
			let canvas = document.createElement("canvas");
			canvas.width = imgWidth;
			canvas.height = imgHeight;
			let ctx: any = canvas.getContext("2d");
			ctx.fillStyle = "#fff";
			ctx.fillRect(0, 0, imgWidth, imgHeight);
			ctx.drawImage(videoElement, x, y, width, height);
			let src = canvas.toDataURL("image/png"); // 完成base64图片的创建

			const dataURLtoFile = (dataurl: string, filename: string) => {
				let arr: any = dataurl.split(","),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				return new File([u8arr], filename, { type: mime });
			};

			const name = file.name.slice(0, file.name.lastIndexOf("."));
			const imageFile = dataURLtoFile(src, `${name}.png`);

			const formData = new FormData();
			formData.append("file", imageFile);

			const { result }: any = await uploadFile(formData, option);
			const { full_path: path, file_name: fileName } = result;

			resolve({
				path,
				fileName,
				duration: parseInt(videoElement.duration)
			});
		};
	});
};

/**
 * 拷贝文案至剪切板
 * @param text 文案内容
 * @param successCallback 成功回调
 * @param failedCallback 失败回调
 * @returns
 */
export const copyToClipboard = (text: string, successCallback?: Function, failedCallback?: Function) => {
	if (!navigator.clipboard) {
		fallbackCopyToClipboard(text, successCallback, failedCallback);
		return;
	}

	navigator.clipboard
		.writeText(text)
		.then(function () {
			console.log("复制成功！");
			successCallback && successCallback();
		})
		.catch(function (err) {
			console.error("拷贝失败：", err);
			failedCallback && failedCallback();
		});
};

export const fallbackCopyToClipboard = (text: string, successCallback?: Function, failedCallback?: Function) => {
	let tempTextArea: HTMLTextAreaElement = document.createElement("textarea");
	tempTextArea.value = text;
	tempTextArea.style.position = "fixed";
	tempTextArea.style.top = "-9999px";
	document.body.appendChild(tempTextArea);
	tempTextArea.select();

	try {
		let successful = document.execCommand("copy");
		let message = successful ? "复制成功！" : "复制失败！";
		console.log(message);
		successCallback && successCallback();
	} catch (err) {
		console.error("拷贝失败：", err);
		failedCallback && failedCallback();
	}

	document.body.removeChild(tempTextArea);
};

//前台支持的国际化
export const supportLocale = [
	{ label: "English", val: "en" },
	{
		label: "Spanish",
		val: "es"
	}
];

// 生成联系人信息字符串
export function getContactString({ company_representative, company_representative_phone, company_representative_email }: any) {
	let contactStr = "";

	contactStr += `Contact Name: ${company_representative || ""},
Phone Number: ${company_representative_phone || ""},
Email: ${company_representative_email || ""}`;

	return contactStr;
}

/**
 * HTML 转义
 * @param str
 * @returns
 */
export const htmlEscape = (str: string = "") => {
	if (!str) return str;

	return str.replace(/[<>&"]/g, match => {
		switch (match) {
			case "<":
				return "&lt;";
			case ">":
				return "&gt;";
			case "&":
				return "&amp;";
			case '"':
				return "&quot;";
			default:
				return match;
		}
	});
};

export const formatHTML = (str: string = "") => {
	if (!str) return str;
	return str.replace(/\n/g, "<br/>");
};

/**
 * @description 去除对象属性值前后的空格
 * @param params
 * @returns
 */
export const trimObjectValue = (params: Record<string, any>) => {
	if (Object.prototype.toString.call(params) === "[object Object]") {
		const obj = {};

		for (const key in params) {
			if (typeof params[key] === "string") {
				obj[key] = params[key].trim();
			} else {
				obj[key] = trimObjectValue(params[key]);
			}
		}
		return obj;
	} else {
		return params;
	}
};

export const isEmptyValue = val => {
	return val === undefined || val === null || val === "";
};

/**
 * 比较两个对象的属性和值是否一一对应
 * @param {Object} objA - 第一个对象
 * @param {Object} objB - 第二个对象
 * @returns {boolean} - 如果两个对象的属性和值一一对应则返回 true，否则返回 false
 */
export const matchObject = (objA: Record<string, any>, objB: Record<string, any>) => {
	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		return false;
	}

	for (const key of keysA) {
		if (!objB.hasOwnProperty(key)) {
			return false;
		}

		if (objA[key] !== objB[key]) {
			return false;
		}
	}

	return true;
};

/**
 * @description 根据 key 将数组转化成对象
 * @param arr 数组
 * @param key 数组对象中键名
 * @returns
 */
export const arrayToMap = (arr: Array<Record<string, any>>, key: string): Record<string, any> => {
	const map = {};

	arr.forEach((item: Record<string, any>) => {
		map[item[key]] = true;
	});

	return map;
};

/**
 * @description 匹配表格列所有的列是否相同
 *
 * @param columns
 * @param newColumns
 */
export const isMatchAllTablePropsItem = (localColumns: Array<Record<string, any>>, columns: Array<Record<string, any>>) => {
	if (!localColumns.length || localColumns.length !== columns.length) {
		return false;
	}
	const localColumnMap = arrayToMap(localColumns, "prop");
	const columnMap = arrayToMap(columns, "prop");

	return matchObject(localColumnMap, columnMap);
};

/**
 * @description 是否是有效的经纬度信息
 * @param position
 * @returns
 */
export function isValidPosition(position: any) {
	if (typeof position?.lat === "number" && typeof position?.lng === "number") {
		return true;
	}
	return false;
}

// 交换数组两个元素
export const reverse = (list: Array<any>, i: number, j: number) => {
	const temp = list[i];
	list[i] = list[j];
	list[j] = temp;
};

/**
 *
 * @param url 图片 url
 * @param name 图片名称
 */
export const downloadImage = (url: string, name: string) => {
	const a = document.createElement("a");
	a.href = url;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export const isUnDef = (val: any): boolean => val === null || val === undefined;

/**
 * @description 处理 prop 为多级嵌套的情况，返回的数据 (列如: prop: user.name)
 * @param {Object} row 当前行数据
 * @param {String} prop 当前 prop
 * @returns {*}
 * */
export function handleRowAccordingToProp(row: { [key: string]: any }, prop: string) {
	if (!prop?.includes(".")) return row[prop] ?? "--";
	prop.split(".").forEach(item => (row = row[item] ?? "--"));
	return row;
}

/**
 * @description 处理 prop，当 prop 为多级嵌套时 ==> 返回最后一级 prop
 * @param {String} prop 当前 prop
 * @returns {String}
 * */
export function handleProp(prop: string) {
	const propArr = prop.split(".");
	if (propArr.length == 1) return prop;
	return propArr[propArr.length - 1];
}

/**
 * @description 生成唯一 uuid
 * @returns {String}
 */
export function generateUUID() {
	let uuid = "";
	for (let i = 0; i < 32; i++) {
		let random = (Math.random() * 16) | 0;
		if (i === 8 || i === 12 || i === 16 || i === 20) uuid += "-";
		uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
	}
	return uuid;
}

// 格式化金额
export const formatAmounts = (value: number | string, fractionDigits = 0) => {
	if (isNaN(Number(value))) {
		throw new Error("Invalid number");
	}

	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits
	}).format(Number(value));
};

// 格式化数字
export const formatNumber = (value: number | string) => {
	if (isNaN(Number(value))) {
		throw new Error("Invalid number");
	}
	return new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	}).format(Number(value));
};

export const removeTrim = (params: Record<string, any>) => {
	if (Object.prototype.toString.call(params) === "[object Object]") {
		const obj = {};
		for (const key in params) {
			if (typeof params[key] === "string") {
				obj[key] = params[key].trim();
			} else {
				obj[key] = removeTrim(params[key]);
			}
		}
		return obj;
	} else {
		return params;
	}
};
