const referrerRoutes: Menu.MenuOptions[] = [
	{
		path: "/referrer",
		name: "referrer-leads",
		meta: {
			icon: "referral",
			title: "推荐线索",
			enTitle: "Referrer Manage",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/referrer/list",
				name: "referrer-list",
				component: "/referrer/list",
				meta: {
					icon: "referral-list",
					title: "推荐列表",
					enTitle: "Referrer List",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/referrer/approve",
				name: "referrer-approve",
				component: "/referrer/approve",
				meta: {
					icon: "review",
					title: "合规审核",
					enTitle: "Approve",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/referrer/w-9",
				name: "referrer-w-9",
				component: "/referrer/w-9",
				meta: {
					icon: "w-9-review",
					title: "W-9审核",
					enTitle: "W-9",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/referrer/rewards",
				name: "referrer-rewards",
				component: "/referrer/rewards",
				meta: {
					icon: "rewards",
					title: "奖励发放",
					enTitle: "Rewards",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/referrer/detail",
				name: "referrer-detail",
				component: "/referrer/detail",
				meta: {
					icon: "",
					title: "详情",
					enTitle: "Detail",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/referrer/user-manage",
				name: "referrer-user-manage",
				component: "/referrer/user-manage",
				meta: {
					icon: "user-manage",
					title: "用户管理",
					enTitle: "User Manage",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/referrer/user-detail",
				name: "referrer-user-detail",
				component: "/referrer/user-detail",
				meta: {
					icon: "",
					title: "详情",
					enTitle: "Detail",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			}
		]
	}
];

export default referrerRoutes;
